import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, PageProps } from "gatsby";
import Image from "../components/image";

const NotFoundPage = ({ data }: PageProps<Queries.NotFoundPageQuery>) => (
  <Layout webStoreImage={
    <Image image={data.webStore?.childImageSharp?.gatsbyImageData}
           alt="Available in the Chrome Web Store" />
  }>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export const query = graphql`
    query NotFoundPage {
        webStore: file(relativePath: {eq: "chrome-web-store-button@2x.png"}) {
            childImageSharp {
                gatsbyImageData(width: 196, quality: 100, placeholder: NONE, layout: FIXED)
            }
        }
    }
`;

export default NotFoundPage;
